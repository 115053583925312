const UsingDockerContainerization = () =>
  import(
    /* webpackChunkName: "using-docker-containerization" */ "@/autoblog/blog/UsingDockerContainerization.vue"
  )

const routes = [
  {
    path: "/blog/using-docker-containerization",
    name: "using-docker-containerization",
    component: UsingDockerContainerization,
  },
]

export default routes
