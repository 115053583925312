



























import { Component, Vue } from "vue-property-decorator"
import LogoText from "@/components/logo/LogoText.vue"

@Component({
  components: {
    LogoText,
  },
})
export default class NavBar extends Vue {
  public showNav: boolean = false
}
