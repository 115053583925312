









































import { Component, Vue } from "vue-property-decorator"

@Component
export default class FooterBar extends Vue {
  public currYear: number = new Date().getFullYear()
}
